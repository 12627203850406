/* global GTM_ID, GTM_GLOBAL_ID */
import Vue from 'vue'
import VueRouter from "vue-router";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import DashboardPlugin from "./material-dashboard";
import VueProgressBar from 'vue-progressbar'
import './validationRules'
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'
import VueGtm from 'vue-gtm'
import Ads from 'vue-google-adsense'
import VueSweetalert2 from 'vue-sweetalert2'
import VueSignaturePad from 'vue-signature-pad'

import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(require('vue-script2'))
Vue.use(VueSweetalert2)
Vue.use(VueSignaturePad)
Vue.use(Ads.Adsense)
Vue.use(VueProgressBar, {
  color: 'white',
  failedColor: 'red',
  height: '3px'
})

let gtmIds = [GTM_GLOBAL_ID]
if (GTM_ID !== "") {
  gtmIds.push(GTM_ID)
} else {
  gtmIds = gtmIds[0]
}

Vue.use(VueGtm, {
  id: gtmIds, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router // Pass the router instance to automatically sync with router (optional)
})

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(VueRouter)
Vue.use(DashboardPlugin)
Vue.use(VueSocialSharing)

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

store.$app = app
