import Vue from 'vue'
import VueRouter from 'vue-router'
import CampaignLayout from '@/layouts/CampaignLayout'
import RegistrationLayout from '@/layouts/RegistrationLayout'
import store from '../store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: CampaignLayout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/Home.vue'),
        requireAcceptance: true
      },
      {
        path: '/pre',
        name: 'preCampaign',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/PreCampaign.vue'),
        requireAcceptance: true
      },
      {
        path: '/post',
        name: 'postCampaign',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/PostCampaign.vue'),
        requireAcceptance: true
      },
      {
        path: '/c/:slug',
        name: 'content',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/Content.vue'),
        requireAcceptance: true
      },
      {
        path: '/info',
        name: 'info',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/InfoPage.vue'),
        children: [
          {
            path: 'legal/legal_basis',
            name: 'legal_basis',
            component: () => import(/* webpackChunkName: "hitsbook" */ '../views/InfoPageLegalDetail.vue'),
            requireAcceptance: false
          },
          {
            path: 'legal/privacy_policy',
            name: 'privacy_policy',
            component: () => import(/* webpackChunkName: "hitsbook" */ '../views/InfoPageLegalDetail.vue'),
            requireAcceptance: false
          },
          {
            path: 'legal/cookies_policy',
            name: 'cookies_policy',
            component: () => import(/* webpackChunkName: "hitsbook" */ '../views/InfoPageLegalDetail.vue'),
            requireAcceptance: false
          },
          {
            path: 'legal/terms',
            name: 'terms',
            component: () => import(/* webpackChunkName: "hitsbook" */ '../views/InfoPageLegalDetail.vue'),
            requireAcceptance: false
          },
          {
            path: ':slug',
            name: 'infopage',
            component: () => import(/* webpackChunkName: "hitsbook" */ '../views/InfoPageDetail.vue'),
            requireAcceptance: false
          }
        ]
      }
    ]
  },
  {
    path: '/accounts',
    component: RegistrationLayout,
    children: [
      {
        path: 'register-basic',
        name: 'register-basic',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/Register.vue'),
        requireAcceptance: false
      },
      {
        path: 'participate',
        name: 'participate',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/Participate.vue'),
        requireAcceptance: false
      },
      {
        path: 'pin',
        name: 'pin',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/Pin.vue'),
        requireAcceptance: false
      },
      {
        path: 'required-data',
        name: 'required-data',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/RequiredData.vue'),
        requireAcceptance: false
      },
      {
        path: 'required-data-participate',
        name: 'required-data-participate',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/RequiredDataParticipate.vue'),
        requireAcceptance: false
      },
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/Login.vue'),
        requireAcceptance: false
      },
      {
        path: 'logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/Logout.vue'),
        requireAcceptance: false
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  let hasAcceptance = store.getters.getUserAcceptance
  if (to.requireAcceptance && !hasAcceptance) {
    next({ "name": "required-data" })
  }
  next()
})

export default router
