import DropDown from "./components/Dropdown.vue";

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import en from 'vee-validate/dist/locale/en.json';
import es from 'vee-validate/dist/locale/es.json';
import pt from 'vee-validate/dist/locale/pt_BR.json';

// Install vee-validate locales.
localize({
  en,
  es,
  pt
});

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("drop-down", DropDown);
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
  }
};

export default GlobalComponents;
