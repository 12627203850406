<template>
  <div class="publi m-1" v-if="!isEmpty">
    <div v-if="ad.type === 'image'" class="text-center">
      <a :href="ad.url" target="_blank" class="mx-auto">
        <img :src="ad.image" class="img-fluid" alt="banner">
      </a>
    </div>
    <div v-else>
      <Adsense
        :data-ad-client="ad.ad_client"
        :data-ad-slot="ad.ad_slot">
      </Adsense>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    ad: {
      type: Object,
      default: () => {
        return {
          type: "image",
          url: "javascript:;",
          image: "https://placehold.it/728x80.png"
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getCampaign']),
    isEmpty () {
      let obj = this.ad
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object
    }
  }
};
</script>
