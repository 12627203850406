<template>
  <footer class="footer footer-campaign">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center d-flex justify-content-between align-items-center">
          <div v-if="false" class="footer-campaign--brand">
            <img :src="footerLogo" />
          </div>
          <ul v-if="showLegal || showPrivacy || showCookies" class="nav footer-campaign--nav">
            <li v-if="showLegal" class="nav-item">
              <router-link class="nav-link" :to="{name:'legal_basis'}">{{ staticTexts.legalAdvice }}</router-link>
            </li>
            <li v-if="showPrivacy" class="nav-item">
              <router-link class="nav-link" :to="{name:'privacy_policy'}">{{ staticTexts.privacyPolicy }}</router-link>
            </li>
            <li v-if="showCookies" class="nav-item">
              <router-link class="nav-link" :to="{name:'cookies_policy'}">{{ staticTexts.cookiesPolicy }}</router-link>
            </li>
          </ul>
          <TheLanguageSelector direction="up" @changed="languageChanged" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
/* global gettext */
import TheLanguageSelector from '../../../common_components/TheLanguageSelector'

export default {
  name: "hb-footer",
  components: {
    TheLanguageSelector
  },
  props: {
    showLegal: {
      type: Boolean,
      default: true
    },
    showPrivacy: {
      type: Boolean,
      default: true
    },
    showCookies: {
      type: Boolean,
      default: true
    },
    footerLogo: {
      type: String,
      default: "/static/img/brand/hitsbook-full-dark.svg"
    }
  },
  computed: {
    staticTexts () {
      return {
        legalAdvice: gettext("Aviso legal"),
        privacyPolicy: gettext("Política de Privacidad"),
        cookiesPolicy: gettext("Política de Cookies")
      };
    }
  },
  methods: {
    languageChanged (newValue) {
      setTimeout(this.reload.bind(this), 2000);
    },
    reload () {
      location.reload();
    }
  }
};
</script>
