/* global WebFont */

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getCampaign', 'getCampaignId']),
    getStyle () {
      return this.getCampaign.theme_style
    },
    getStyling () {
      if (this.$route.name === 'preCampaign') {
        return 'pre'
      }
      if (this.$route.name === 'postCampaign') {
        return 'post'
      }
      return 'default'
    },
    customCSS () {
      return this.getCampaign.custom_css
    },
    cssProps () {
      if (!this.getCampaignId) {
        return {}
      }
      this.$Progress.setColor(this.getCampaign.main_color)
      let primaryColorRGB = this.hexToRGB(this.getCampaign.main_color)
      let secondaryColorRGB = this.hexToRGB(this.getCampaign.secondary_color)
      let accessiblePrimary = (((primaryColorRGB[0] * 299 + primaryColorRGB[1] * 587 + primaryColorRGB[2] * 114) / 1000) - 128) * -1000
      let accessibleSecondary = (((secondaryColorRGB[0] * 299 + secondaryColorRGB[1] * 587 + secondaryColorRGB[2] * 114) / 1000) - 128) * -1000
      if (accessiblePrimary < 0) {
        accessiblePrimary = 0
      } else {
        accessiblePrimary = 255
      }
      if (accessibleSecondary < 0) {
        accessibleSecondary = 0
      } else {
        accessibleSecondary = 255
      }
      let textColor = '#3C4858'
      let backColor = '#FFFFFF'
      let themeContrastColor = 255
      let contentLoaderPrimary = '#eeeeee'
      let contentLoaderSecondary = '#dddddd'
      if (this.getCampaign.theme_style === 'dark') {
        themeContrastColor = 0
        textColor = '#FFFFFF'
        backColor = '#3C4858'
        contentLoaderPrimary = '#111111'
        contentLoaderSecondary = '#222222'
        document.body.classList.add('dark-mode')
      } else {
        document.body.classList.remove('dark-mode')
      }
      if (this.webfontLoaded !== this.getCampaign.main_font_family) {
        WebFont.load({
          google: {
            families: [this.getCampaign.main_font_family]
          }
        })
        this.webfontLoaded = this.getCampaign.main_font_family
      }
      this.setNewCSS(this.getCampaign.custom_css)

      let backgroundColor = this.getCampaign.background_color
      let backgroundImage = this.getCampaign.background_image
      if (this.getStyling === 'pre') {
        backgroundColor = this.getCampaign.pre_campaign_page_background_color
        backgroundImage = this.getCampaign.pre_campaign_background_image
      }
      if (this.getStyling === 'post') {
        backgroundColor = this.getCampaign.post_campaign_page_background_color
        backgroundImage = this.getCampaign.post_campaign_background_image
      }

      if (backgroundImage) {
        backgroundImage = 'url("' + backgroundImage + '")'
      } else {
        backgroundImage = 'none'
      }

      return {
        '--styling': this.getStyling,
        '--font-brand': "'" + this.getCampaign.main_font_family + "', sans-serif",
        '--red-primary': primaryColorRGB[0],
        '--green-primary': primaryColorRGB[1],
        '--blue-primary': primaryColorRGB[2],
        '--alpha-primary': primaryColorRGB[3],
        '--red-secondary': secondaryColorRGB[0],
        '--green-secondary': secondaryColorRGB[1],
        '--blue-secondary': secondaryColorRGB[2],
        '--alpha-secondary': secondaryColorRGB[3],
        '--accessible-color-primary': accessiblePrimary,
        '--accessible-color-secondary': accessibleSecondary,
        '--theme-contrast-color': themeContrastColor,
        '--text-color': textColor,
        '--back-color': backColor,
        '--content-loader-primary': contentLoaderPrimary,
        '--content-loader-secondary': contentLoaderSecondary,
        '--main-color': this.getCampaign.main_color,
        '--secondary-color': this.getCampaign.secondary_color,
        '--background-color': backgroundColor,
        '--background-image-url': backgroundImage
      }
    }
  },
  methods: {
    hexToRGB (hex) {
      if (hex.charAt(0) === '#') {
        hex = hex.substr(1)
      }
      if ((hex.length < 2) || (hex.length > 8)) {
        return false
      }
      let values = hex.split('')
      let r
      let g
      let b
      let a

      if (hex.length === 2) {
        r = parseInt(values[0].toString() + values[1].toString(), 16)
        g = r
        b = r
        a = 1
      } else if (hex.length === 3) {
        r = parseInt(values[0].toString() + values[0].toString(), 16)
        g = parseInt(values[1].toString() + values[1].toString(), 16)
        b = parseInt(values[2].toString() + values[2].toString(), 16)
        a = 1
      } else if (hex.length === 6) {
        r = parseInt(values[0].toString() + values[1].toString(), 16)
        g = parseInt(values[2].toString() + values[3].toString(), 16)
        b = parseInt(values[4].toString() + values[5].toString(), 16)
        a = 1
      } else if (hex.length === 8) {
        r = parseInt(values[0].toString() + values[1].toString(), 16)
        g = parseInt(values[2].toString() + values[3].toString(), 16)
        b = parseInt(values[4].toString() + values[5].toString(), 16)
        a = parseInt(values[6].toString() + values[7].toString(), 16) / 255.0
        return [r, g, b, a]
      } else {
        return false
      }
      return [r, g, b, a]
    },
    setNewCSS (newValue) {
      let styleElement = document.querySelector("style#customcss")
      if (styleElement.styleSheet) {
        styleElement.styleSheet.cssText = newValue
      } else {
        while (styleElement.firstChild) {
          styleElement.removeChild(styleElement.firstChild)
        }
        styleElement.appendChild(document.createTextNode(newValue))
      }
    }
  },
  watch: {
    getStyle(newValue) {
      let documentClasses = document.body.classList
      if (newValue === 'dark') {
        documentClasses.add("dark-mode")
      } else {
        documentClasses.remove("dark-mode")
      }
    },
    customCSS(newValue) {
      this.setNewCSS(newValue)
    }
  },
  data () {
    return {
      webfontLoaded: null
    }
  }
};
