<template>
  <div :style="cssProps" id="bodyMain">
    <vue-progress-bar></vue-progress-bar>
    <div class="wrapper wrapper-full-page wrapper-campaign">
      <notifications></notifications>
      <registration-header-nav></registration-header-nav>
      <div class="content">
        <fade-transition :duration="300" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
      <hb-footer></hb-footer>
    </div>
  </div>
</template>
<script>
/* global CAMPAIGN_ID */
import RegistrationHeaderNav from '@/layouts/RegistrationHeaderNav.vue'
import HbFooter from '@/layouts/HBFooter.vue'
// import MainBanner from '../components/MainBanner.vue'
// import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions'
import { mapActions, mapGetters } from 'vuex'
import MixinCssProps from '@/layouts/MixinCssProps.js'

// import moment from 'moment';
// moment.locale('es');

// const today = new Date()

export default {
  components: {
    RegistrationHeaderNav,
    FadeTransition,
    HbFooter
  },
  mixins: [MixinCssProps],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['getCampaignId'])
  },
  methods: {
    ...mapActions(['doUpdateCampaign', 'registerCampaignInteractions', 'doGetFormExtraFields']),
    async sync () {
      await this.doUpdateCampaign(CAMPAIGN_ID)
      await this.doGetFormExtraFields()
    }
  },
  async created () {
    if (!this.getCampaignId) { // Si ya hay id es pq se ha recuperado info del localStorage, por tanto, no hace falta recuperar la ficha de la campaign
      await this.sync()
      // console.log("refreshed campaign")
    } else {
      setTimeout(this.sync.bind(this), 1000)
    }
  },
  mounted () {
    let interactions = []
    interactions.push({ 'type': 'pageview', 'url': window.location.href })
    this.registerCampaignInteractions({ 'campaign': this.getCampaignId, interactions })
    document.oncontextmenu = () => { return false }
  }
};
</script>

<style lang="scss">
.form-control, .input-group {
  background-color: var(--back-color);
  border-radius: 5px;
}

.page-transition {
  transition: all ease 0.3s;
}

.v-leave-to .page-transition-slide-up, .v-enter .page-transition-slide-up {
  opacity: 0;
  transform: translateY(-20px);
}
.v-enter-to .page-transition-slide-up, v-leave .page-transition-slide-up {
  opacity: 1;
  transform: translateY(0px);
}
.v-leave-to .page-transition-slide-up-2x, .v-enter .page-transition-slide-up-2x {
  opacity: 0;
  transform: translateY(-60px);
}
.v-enter-to .page-transition-slide-up-2x, v-leave .page-transition-slide-up-2x {
  opacity: 1;
  transform: translateY(0px);
}

</style>
