<template>
  <div :style="cssProps" id="bodyMain">
    <div v-if="getSettings.demoData" class="demo-indicator">{{ staticTexts.demo }}</div>
    <vue-progress-bar></vue-progress-bar>
    <notifications></notifications>
    <mobile-header-nav></mobile-header-nav>
    <div class="wrapper wrapper-full-page wrapper-campaign">
      <banner :ad="getCampaign.advertising.super_banner_top" v-if="getCampaign && getCampaign.advertising && getCampaign.advertising.super_banner_top"></banner>
      <header-nav></header-nav>
      <div class="content">
        <fade-transition :duration="300" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
      <banner :ad="getCampaign.advertising.super_banner_bottom" v-if="getCampaign && getCampaign.advertising && getCampaign.advertising.super_banner_bottom"></banner>
      <hb-footer></hb-footer>
    </div>
  </div>
</template>
<script>
/* global gettext, CAMPAIGN_ID */
import MobileHeaderNav from '@/layouts/MobileHeaderNav.vue'
import HeaderNav from '@/layouts/HeaderNav.vue'
import HbFooter from '@/layouts/HBFooter.vue'
import Banner from '@/components/Hitsbook/Banner.vue'
// import MainBanner from '../components/MainBanner.vue'
// import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MixinCssProps from '@/layouts/MixinCssProps.js'

export default {
  components: {
    MobileHeaderNav,
    HeaderNav,
    Banner,
    // MainBanner,
    FadeTransition,
    HbFooter
  },
  mixins: [MixinCssProps],
  data () {
    return {
      timer: null
    }
  },
  metaInfo() {
    return {
      title: this.getCampaign.name
    }
  },
  computed: {
    ...mapGetters(['getAuth', 'getCampaignId', 'getCampaign', 'getSettings']),
    staticTexts () {
      return {
        demo: gettext("DEMO")
      };
    }
  },
  methods: {
    ...mapActions(['doUpdateCampaign', 'doGetInfoPages', 'doUpdateUserInteractions', 'doGetParticipations']),
    ...mapMutations(['setSettings']),
    async sync () {
      await this.doUpdateCampaign(CAMPAIGN_ID)
      await this.doGetInfoPages()
      if (this.getAuth.loggedIn) {
        this.doUpdateUserInteractions(CAMPAIGN_ID)
      }
    },
    handleMessage (message) {
      if (message.data.action === "updateCampaign") {
        this.doUpdateCampaign(CAMPAIGN_ID)
      } else if (message.data.action === "updateInfoPages") {
        this.doGetInfoPages()
      } else if (message.data.action === "settingsChanged") {
        this.setSettings(message.data.settings)
        this.doGetParticipations()
      }
    }
  },
  async created () {
    if (!this.getCampaignId) { // Si ya hay id es pq se ha recuperado info del localStorage, por tanto, no hace falta recuperar la ficha de la campaign
      await this.sync()
      // console.log("refreshed campaign")
    } else {
      setTimeout(this.sync.bind(this), 1000)
    }

    if (window.addEventListener) {
      window.addEventListener('message', this.handleMessage.bind(this), false);
    } else if (window.attachEvent) { // ie8
      window.attachEvent('onmessage', this.handleMessage.bind(this));
    }
  },
  mounted () {
    this.timer = setInterval(this.sync.bind(this), 30000)
    document.oncontextmenu = () => { return false }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
};
</script>

<style lang="scss">

.page-transition {
  transition: all ease 0.3s;
}

.v-leave-to .page-transition-slide-up, .v-enter .page-transition-slide-up {
  opacity: 0;
  transform: translateY(-20px);
}
.v-enter-to .page-transition-slide-up, v-leave .page-transition-slide-up {
  opacity: 1;
  transform: translateY(0px);
}
.v-leave-to .page-transition-slide-up-2x, .v-enter .page-transition-slide-up-2x {
  opacity: 0;
  transform: translateY(-60px);
}
.v-enter-to .page-transition-slide-up-2x, v-leave .page-transition-slide-up-2x {
  opacity: 1;
  transform: translateY(0px);
}
.demo-indicator {
  position: fixed;
  font-weight: 800;
  top: 0px;
  left: 3px;
  color: red;
  text-shadow: 0px 0px 2px black;
  animation-name: demoAnimation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  z-index: 99;
}

@keyframes demoAnimation {
  0% {opacity: 0.1;}
  50% {opacity: 1;}
  100% {opacity: 0.1;}
}

</style>
