<template>
  <ul class="navbar-nav">
    <router-link :to="{name: 'home'}" v-slot="{ href, route, navigate, isActive, isExactActive }">
      <li class="nav-item" :class="{'active': isExactActive}">
        <a :href="href" class="nav-link" @click="navigate">
          <i class="material-icons">home</i> <span>{{ staticTexts.home }}</span>
        </a>
      </li>
    </router-link>
    <router-link :to="{name: 'info'}" v-slot="{ href, route, navigate, isActive, isExactActive }">
      <li class="nav-item" :class="{'active': isActive}">
        <a :href="href" class="nav-link" @click="navigate">
          <i class="material-icons">info</i> <span>{{ staticTexts.moreInfo }}</span>
        </a>
      </li>
    </router-link>
    <router-link :to="{name: 'logout'}" v-slot="{ href, route, navigate, isActive, isExactActive }" v-if="getCampaign.objective.login_enabled && getAuth.loggedIn">
      <li class="nav-item" :class="{'active': isActive}">
        <a :href="href" class="nav-link" @click="navigate">
          <i class="material-icons">power_settings_new</i> <span>{{ staticTexts.logOut }}</span>
        </a>
      </li>
    </router-link>
    <router-link :to="{name: 'participate'}" v-slot="{ href, route, navigate, isActive, isExactActive }" v-if="canParticipate">
      <li class="nav-item">
        <a :href="href" class="nav-link nav-link-video-up text-success" @click="navigate">
          <i class="material-icons">video_call</i> <span>{{ staticTexts.participate }}</span>
        </a>
      </li>
    </router-link>
    <router-link :to="{name: 'register-basic'}" v-slot="{ href, route, navigate, isActive, isExactActive }" v-else>
      <li class="nav-item">
        <a :href="href" class="nav-link nav-link-video-up text-success" @click="navigate">
          <i class="material-icons">power_settings_new</i> <span>{{ staticTexts.register }}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
/* global gettext */
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['getCampaign', 'getAuth', 'getSettings']),
    canParticipate () {
      return this.getCampaign.extended_state === "participate"
    },
    staticTexts () {
      return {
        home: gettext("Inicio"),
        logIn: gettext("Login"),
        logOut: gettext("Desconectar"),
        moreInfo: gettext("+ info"),
        participate: gettext("Participar"),
        register: gettext("Registro")
      };
    }
  }
};
</script>
