<template>
  <div
    :class="[
      { open: isOpen },
      { dropdown: direction === 'down' },
      { dropup: direction === 'up' }
    ]"
    @click="toggleDropDown"
    v-click-outside="closeDropDown"
  > 
    <form :action="SET_LANGUAGE_URL" ref="langform" method="post" style="display:none">
      <input type="hidden" name="csrfmiddlewaretoken" :value="CSRF_TOKEN">
      <input type="hidden" name="language" :value="selected[0]">
    </form>
    <span class="btn-group">
      <button
        class="btn btn-light dropdown-toggle"
        data-toggle="dropdown"
        href="javascript:void(0)"
        type="button" aria-haspopup="true" aria-expanded="false"
      >
        <img :src="selected[2]" class="mr-2">
        {{selected[1]}}
      </button>
    </span>
    <div class="dropdown-menu"  :class="get_right_class">
      <a class="dropdown-item" href="javascript:void(0)" v-for="option in options" :key="option[0]" @click.prevent="select(option)">
        <img :src="option[2]" class="mr-2">
        {{option[1]}}
      </a>
    </div>
  </div>
</template>

<script>
/* global gettext, CURRENT_LANGUAGE_CODE, AVAILABLE_LANGUAGES, SET_LANGUAGE_URL, CSRF_TOKEN */

export default {
    name: "TheLanguageSelector",
    props: {
        dropRight: {
            type: Boolean,
            default: true
        },
        direction: {
            type: String,
            default: "down"
        }
    },
    data() {
        return {
            isOpen: false,
            selected: ["", gettext("Idioma"), ""],
            CURRENT_LANGUAGE_CODE: CURRENT_LANGUAGE_CODE,
            AVAILABLE_LANGUAGES: AVAILABLE_LANGUAGES,
            SET_LANGUAGE_URL: SET_LANGUAGE_URL,
            CSRF_TOKEN: CSRF_TOKEN
        };
    },
    computed: {
        options () {
            let ops = [];
            this.AVAILABLE_LANGUAGES.forEach((item)=>{
                ops.push([item.code, item.name_local, "/static/img/flags/" + item.code + ".png"]);
            });
            return ops;
        },
        get_right_class () {
            if (this.dropRight) {
                return "dropdown-menu-right";
            } else {
                return "";
            }
        }
    },
    methods: {
        select (option) {
            this.selected = option;
            this.$emit("changed", this.selected[0], this.selected[1]);
            this.$nextTick(() => {
                this.$refs["langform"].submit();
            });
        },
        toggleDropDown () {
            this.isOpen = !this.isOpen;
        },
        closeDropDown () {
            this.isOpen = false;
        }
    },
    mounted () {
        let selected = this.options.filter((i) => { 
            return i[0] === CURRENT_LANGUAGE_CODE;
        });
        this.selected = selected[0];
    }
};

</script>
