<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-campaign no-shadow sps">
    <div class="container container-campaign">
      <div class="navbar-brand-wrapper">
        <router-link class="navbar-brand" to="/">
          <img :src="getCampaign.logo">
        </router-link>
      </div>
      <div class="justify-content-end d-flex align-items-center">
        <div class="navbar-box navbar-box-bottom d-none d-lg-block">
          <main-menu></main-menu>
        </div>
        <div class="navbar-box navbar-box-line">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link" v-if="navShareAvailable && getCampaign.social_network_share_enabled" @click.prevent="showSharer">
                <i class="material-icons">share</i> <span>{{ staticTexts.share }}</span>
              </a>
            </li>
            <li class="nav-item" v-if="!navShareAvailable && getCampaign.social_network_share_enabled">
              <dropdown :showCaret="false">
                <template v-slot:title>
                  <a href="javascript:void(0)" class="nav-link nav-link-special">
                    <i class="material-icons">share</i> <span>{{ staticTexts.share }}</span>
                  </a>
                </template>

                <div class="dropdown-menu dropdown-menu-right">

                  <ShareNetwork
                      network="facebook"
                      @open="registerShare"
                      :url="urlToShare"
                      :title="getShareTitle"
                      :description="getCampaign.share_text"
                      :hashtags="getCampaign.hashtag"
                      class="dropdown-item btn btn-link"
                      v-if="networkIsEnabled('facebook')"
                    >
                      <i class="fab fa-fw fa-facebook mr-2"></i> {{ staticTexts.facebook }}
                  </ShareNetwork>
                  <ShareNetwork
                      network="pinterest"
                      @open="registerShare"
                      :url="urlToShare"
                      :title="getShareTitle"
                      :description="getCampaign.share_text"
                      :hashtags="getCampaign.hashtag"
                      class="dropdown-item btn btn-link"
                      v-if="networkIsEnabled('pinterest')"
                    >
                      <i class="fab fa-fw fa-pinterest mr-2"></i> {{ staticTexts.pinterest}}
                  </ShareNetwork>
                  <ShareNetwork
                      network="reddit"
                      @open="registerShare"
                      :url="urlToShare"
                      :title="getShareTitle"
                      :description="getCampaign.share_text"
                      :hashtags="getCampaign.hashtag"
                      class="dropdown-item btn btn-link"
                      v-if="networkIsEnabled('reddit')"
                    >
                      <i class="fab fa-fw fa-reddit mr-2"></i> {{ staticTexts.reddit }}
                  </ShareNetwork>
                  <ShareNetwork
                      network="twitter"
                      @open="registerShare"
                      :url="urlToShare"
                      :title="getShareTitle"
                      :description="getCampaign.share_text"
                      :hashtags="getCampaign.hashtag"
                      class="dropdown-item btn btn-link"
                      v-if="networkIsEnabled('twitter')"
                    >
                      <i class="fab fa-fw fa-twitter mr-2"></i> {{ staticTexts.twitter }}
                  </ShareNetwork>
                  <ShareNetwork
                      network="whatsapp"
                      @open="registerShare"
                      :url="urlToShare"
                      :title="getShareTitle"
                      :description="getCampaign.share_text"
                      :hashtags="getCampaign.hashtag"
                      class="dropdown-item btn btn-link"
                      v-if="networkIsEnabled('whatsapp')"
                    >
                      <i class="fab fa-fw fa-whatsapp mr-2"></i> {{ staticTexts.whatsapp }}
                  </ShareNetwork>
                  <ShareNetwork
                      network="email"
                      @open="registerShare"
                      :url="urlToShare"
                      :title="getShareTitle"
                      :description="getCampaign.share_text"
                      :hashtags="getCampaign.hashtag"
                      class="dropdown-item btn btn-link"
                      v-if="networkIsEnabled('email')"
                    >
                      <i class="far fa-fw fa-envelope mr-2"></i> {{ staticTexts.email }}
                  </ShareNetwork>
                  <ShareNetwork
                      network="sms"
                      @open="registerShare"
                      :url="urlToShare"
                      :title="getShareTitle"
                      :description="getCampaign.share_text"
                      :hashtags="getCampaign.hashtag"
                      class="dropdown-item btn btn-link"
                      v-if="networkIsEnabled('sms')"
                    >
                      <i class="far fa-fw fa-comment-dots mr-2"></i> {{ staticTexts.sms }}
                  </ShareNetwork>

                </div>
              </dropdown>
            </li>
            <li class="nav-item" v-if="getCampaign.objective.search_enabled">
              <a href="#" class="nav-link">
                <i class="material-icons">search</i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
/* global gettext */
// import ScrollPosStyler from 'scrollpos-styler'
import { mapGetters, mapActions } from 'vuex'
import MainMenu from '@/layouts/MainMenu'
import ScrollPosStyler from 'scrollpos-styler';
import Dropdown from '@/components/Dropdown'

export default {
  name: "HeaderNav",
  components: {
    MainMenu,
    Dropdown
  },
  methods: {
    ...mapActions(['registerParticipationInteractions', 'registerCampaignInteractions']),
    initScrollPosStyler () {
      this.sp = ScrollPosStyler
      // Este movimiento simulado fuerza a que se inicialice correctamente el scrollposstyler
      window.scrollTo(0, 0)
      window.scrollTo(0, 300)
      window.scrollTo(0, 0)
    },
    networkIsEnabled (network) {
      let items = this.getCampaign.share_social_networks.filter(item => item.code === network)
      return items.length > 0
    },
    registerShare (network) {
      let interactions = []
      interactions.push({ 'type': 'share', 'url': window.location.href, 'network': network })
      if (this.currentRouteName === 'content') {
        this.registerParticipationInteractions({ 'participation': this.getParticipation.id, interactions })
      } else {
        this.registerCampaignInteractions({ 'campaign': this.getCampaignId, interactions })
      }
    },
    showSharer () {
      navigator.share({
        title: this.getCampaign.name,
        text: this.getCampaign.share_text,
        url: this.urlToShare
      }).then(() => {
        // console.log('Successful share')
        this.registerShare('native')
      }).catch(() => {
        // console.log('Error sharing:', error)
      });
    }
  },
  data () {
    return {
      sp: null,
      currentURI: null
    }
  },
  watch: {
    $route (to, from) {
      // console.log(to, from)
      this.currentURI = window.location.protocol + "//" + window.location.host + "/#" + to.fullPath
    }
  },
  computed: {
    ...mapGetters(['getCampaign', 'getParticipation', 'getCampaignId']),
    currentRouteName() {
      return this.$route.name;
    },
    urlToShare () {
      if (this.getParticipation) {
        return window.location.protocol + "//" + window.location.host + "/share/" + this.getParticipation.id + "/";
      } else {
        return this.currentURI;
      }
    },
    getShareTitle () {
      if (this.getParticipation) {
        return this.getParticipation.title
      }
      if (this.getCampaign) {
        return this.getCampaign.name
      }
      return "Loading..."
    },
    currentRoute () {
      return this.$router.currentRoute.fullPath
    },
    isAccountRoute () {
      return this.$router.currentRoute.fullPath.includes("/accounts/")
    },
    navShareAvailable () {
      return !!navigator.share
    },
    staticTexts () {
      return {
        share: gettext("Compartir"),
        facebook: gettext("Facebook"),
        pinterest: gettext("Pinterest"),
        twitter: gettext("Twitter"),
        reddit: gettext("Reddit"),
        whatsapp: gettext("WhatsApp"),
        email: gettext("Email"),
        sms: gettext("SMS")
      };
    }
  },
  created () {
    setTimeout(this.initScrollPosStyler.bind(this), 500)
    this.currentURI = window.location.protocol + "//" + window.location.host + "/#" + this.currentRoute
  }
}
</script>
