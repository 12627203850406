<template>
  <nav class="navbar navbar-campaign-mobile d-block d-lg-none">
    <div class="navbar-box">
      <main-menu></main-menu>
    </div>
  </nav>
</template>

<script>
import MainMenu from '@/layouts/MainMenu'

export default {
  components: {
    MainMenu
  },
  data () {
    return {
    }
  },
  computed: {
  }
};
</script>
