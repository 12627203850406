/* global gettext */
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASEURL

axios.defaults.xsrfHeaderName = "X_CSRFTOKEN"
axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.withCredentials = true

export const HitsbookAPI = {
  getCookie (name) {
    let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  },
  getConfig (data) {
    var config = this.getBaseHeaders()
    if (data) {
      config['headers']['Content-Type'] = 'multipart/form-data'
    }
    return config
  },
  getUploadConfig (data) {
    var config = this.getConfig(data)
    config['onUploadProgress'] = data['uploadHandler']
    return config
  },
  getBaseHeaders () {
    var config = { 'headers': {} }
    config['headers'][axios.defaults.xsrfHeaderName] = this.getCookie('csrftoken')
    return config
  },
  getPayload (data) {
    var payload = new FormData()
    for (var key in data) {
      if (key !== "uploadHandler") {
        if (Array.isArray(data[key])) {
          data[key].forEach(item => {
            payload.append(key, item)
          })
        } else {
          payload.set(key, data[key])
        }
      }
    }
    payload.set('csrfmiddlewaretoken', this.getCookie('csrftoken'))
    return payload
  },
  getCampaign (id) {
    const config = {}
    return axios.get('/campaign/' + id + '/', config)
  },
  canParticipate (id) {
    const config = {}
    return axios.get('/campaign/' + id + '/can_participate/', config)
  },
  getParticipations (id, tabname, ranking, category, size = 12, page = 1) {
    const config = {
      params: {
        'tab': tabname,
        'ranking': ranking,
        'category': category,
        'size': size,
        'page': page
      }
    }
    return axios.get('/campaign/' + id + '/participations/', config)
  },
  getParticipationDetails (id, campaignId, tabname, ranking, category, relatedCount) {
    const config = {
      params: {
        'tab': tabname,
        'ranking': ranking,
        'category': category,
        'related_count': relatedCount,
        'campaign_id': campaignId
      }
    }
    return axios.get('/participation/' + id + '/', config)
  },
  getInfoPages (id) {
    const config = {
    }
    return axios.get('/campaign/' + id + '/infopages/', config)
  },
  registerView (id) {
    let data = {}
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/participation/' + id + '/view/', payload, config)
  },
  registerCampaignInteractions (id, interactionsList) {
    let payload = { "interactions": interactionsList }
    let config = this.getBaseHeaders()
    return axios.post('/campaign/' + id + '/register_interactions/', payload, config)
  },
  registerParticipationInteractions (id, interactionsList) {
    let payload = { "interactions": interactionsList }
    let config = this.getBaseHeaders()
    return axios.post('/participation/' + id + '/register_interactions/', payload, config)
  },
  getUserInteractions (id) {
    return axios.get('/campaign/' + id + '/get_user_interactions/')
  },
  registerVote (id) {
    let data = {}
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/participation/' + id + '/vote/', payload, config)
  },
  registerReaction (id, rtype) {
    let data = {}
    if (rtype) {
      data["r"] = rtype
    }
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/participation/' + id + '/reaction/', payload, config)
  },
  registerApplauses (id, amount) {
    let data = {
      amount
    }
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/participation/' + id + '/applause/', payload, config)
  },
  mock (success, timeout) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (success) {
          resolve()
        } else {
          reject(new Error(gettext("Se ha producido un error")))
        }
      }, timeout)
    })
  },
  mockUpload (id, data) {
    let payload = this.getPayload(data)
    let config = this.getUploadConfig(data)
    return axios.post('/campaign/' + id + '/participate/', payload, config)
  },
  participateVideoInternal (data) {
    let payload = this.getPayload(data)
    let config = this.getUploadConfig(data)
    return axios.post('/participation/participate-video-internal/', payload, config)
  },
  participateVideoExternal (data) {
    let payload = this.getPayload(data)
    let config = this.getUploadConfig(data)
    return axios.post('/participation/participate-video-external/', payload, config)
  },
  participatePhotoInternal (data) {
    let payload = this.getPayload(data)
    let config = this.getUploadConfig(data)
    return axios.post('/participation/participate-photo/', payload, config)
  },
  login (email, password) {
    let data = {
      email,
      password
    }
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/login/', payload, config)
  },
  logout () {
    let data = {
    }
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/logout/', payload, config)
  },
  currentUser () {
    return axios.get('/account/current-user/')
  },
  formExtraFields () {
    return axios.get('/form-extra-fields/')
  },
  formExtraFieldsRRSSVote () {
    return axios.get('/form-extra-fields/user-form-extra-fields-to-vote/')
  },
  formExtraFieldsRRSSParticipate () {
    return axios.get('/form-extra-fields/user-form-extra-fields-to-participate/')
  },
  requestEmailValidationCode (email, token) {
    let data = {
      email,
      token
    }
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/request-email-validation-code/', payload, config)
  },
  requestEmailRRSSValidationCode (email, token) {
    let data = {
      email,
      token
    }
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/request-social-network-user-email-validation-code/', payload, config)
  },
  validateEmail (email, token, code) {
    let data = {
      email,
      token,
      code
    }
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/validate-email/', payload, config)
  },
  validateEmailRRSS (email, token, code) {
    let data = {
      email,
      token,
      code
    }
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/social-network-user-validate-email/', payload, config)
  },
  registerByEmailToVote (data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/register-by-email-to-vote/', payload, config)
  },
  registerByEmailToParticipate (data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/register-by-email-to-participate/', payload, config)
  },
  registerByEmailToPreParticipate (data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/register-by-email-to-preparticipate/', payload, config)
  },
  updateFieldsToVote(data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/update-extra-fields-to-vote/', payload, config)
  },
  updateFieldsToParticipate(data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/update-extra-fields-to-participate/', payload, config)
  },
  resetPassword (email, token) {
    let data = {
      email,
      token
    }
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/reset-password/', payload, config)
  },
  resetPasswordValidation (email, token, code) {
    let data = {
      email,
      token,
      code
    }
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/account/reset-password-validation/', payload, config)
  }
}
