var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"navbar-nav"},[_c('router-link',{attrs:{"to":{name: 'home'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:{'active': isExactActive}},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"material-icons"},[_vm._v("home")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.staticTexts.home))])])])]}}])}),_c('router-link',{attrs:{"to":{name: 'info'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:{'active': isActive}},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.staticTexts.moreInfo))])])])]}}])}),(_vm.getCampaign.objective.login_enabled && _vm.getAuth.loggedIn)?_c('router-link',{attrs:{"to":{name: 'logout'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:{'active': isActive}},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"material-icons"},[_vm._v("power_settings_new")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.staticTexts.logOut))])])])]}}],null,false,893974035)}):_vm._e(),(_vm.canParticipate)?_c('router-link',{attrs:{"to":{name: 'participate'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link nav-link-video-up text-success",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"material-icons"},[_vm._v("video_call")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.staticTexts.participate))])])])]}}],null,false,1263511242)}):_c('router-link',{attrs:{"to":{name: 'register-basic'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link nav-link-video-up text-success",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"material-icons"},[_vm._v("power_settings_new")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.staticTexts.register))])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }