<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-campaign no-shadow sps">
    <div class="container container-campaign">
      <div class="navbar-brand-wrapper">
        <router-link class="navbar-brand" to="/">
          <img :src="getCampaign.logo">
        </router-link>
      </div>
      <div class="justify-content-end d-flex align-items-center">
        <div class="navbar-box navbar-box-bottom">
          <ul class="navbar-nav">
            <li class="nav-item nav-item--final">
              <router-link class="nav-link" :to="getNextUrl">
                <i class="material-icons">
                  arrow_back
                </i>
                <span>{{staticTexts.backTo}} <strong class="color-custom-primary">{{ getCampaign.name }}</strong></span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>

</template>

<script>
/* global gettext */

import { mapGetters } from 'vuex'

export default {
  components: {
  },
  methods: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['getCampaign', 'getLoginRequired']),
    getNextUrl () {
      if (this.getLoginRequired.url) {
        return this.getLoginRequired.url
      } else {
        return "/"
      }
    },
    staticTexts () {
      return {
        backTo: gettext("Vuelve a")
      };
    }
  }
};
</script>
