/* global gettext, CURRENT_LANGUAGE_CODE */
import * as rules from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

var langCode = CURRENT_LANGUAGE_CODE;
if (langCode === "pt-br") {
  langCode = "pt_BR"
}
if (langCode === "es-mx") {
  langCode = "es"
}

const veeLang = require(`vee-validate/dist/locale/${langCode}.json`);

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: veeLang.messages[rule] // assign message
  })
})

extend('acceptance', {
  computesRequired: true,
  validate: (value) => {
    return value === 'accepted'
  },
  message: gettext('Es obligatorio aceptar esto para poder continuar')
})

extend('checkboxrequired', {
  computesRequired: true,
  validate: (value) => {
    return value === true
  },
  message: gettext('Es obligatorio aceptar esto para poder continuar')
})
